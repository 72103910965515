import {
  arrayOf,
  customType,
  extend,
  alias,
  namedFragment,
  shape,
  typename,
  string as stringType,
  bool as boolType,
} from '@thd-nucleus/data-sources';
import { SponsoredBanner as SponsoredBannerComponent } from '@thd-olt-component-react/sponsored-content';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  imageHotspot: shape({
    id: stringType(),
    componentName: stringType(),
    title: stringType(),
    imageUrl: stringType(),
    hotspots: stringType(),
    hotspotActions: arrayOf(shape({
      id: stringType(),
      url: stringType(),
      title: stringType(),
    })),
    __typename: typename('ImageHotspot')
  }),
  __typename: typename('DamMedia')
});

const HeroFlattenImage = namedFragment({
  inline: true,
  fragmentType: 'HeroFlattenImage',
  fragmentAlias: 'HeroFlattenImage'
}).shape({
  id: stringType(),
  title: stringType(),
  previewImage: DamMediaFragment,
  mobilePreviewImage: DamMediaFragment,
  link: stringType(),
  altText: stringType(),
  __typename: typename('HeroFlattenImage')
});

const SponsoredBanner = namedFragment({
  inline: false,
  fragmentType: 'SponsoredBanner',
  fragmentAlias: 'SponsoredBanner'
}).shape({
  id: stringType(),
  customRenderId: stringType(),
  schema: stringType(),
  imgDesktop: stringType(),
  imgMobile: stringType(),
  clickthruUrl: stringType(),
  theme: stringType(),
  showBanner: boolType(),
  specialDefaultBanner: shape({
    imgDesktop: stringType(),
    imgMobile: stringType(),
    clickthruUrl: stringType(),
    useSpecialBanner: boolType()
  }),
  __typename: typename('SponsoredBanner'),
});

const Section = namedFragment({
  inline: false,
  fragmentType: 'Section',
  fragmentAlias: 'Section'
}).shape({
  id: stringType(),
  isCarousel: boolType(),
  isAutoplay: boolType(),
  components: arrayOf(shape({ HeroFlattenImage })),
  customRenderId: stringType(),
  theme: stringType(),
  __typename: typename('Section'),
});

export const bentoHeroDataModel = extend({
  bentoHeroComponent: alias('component').params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['BentoHero']).isRequired(),
  }).shape({
    BentoHero: namedFragment({
      inline: true,
      fragmentType: 'BentoHero',
      fragmentAlias: 'BentoHeroFields'
    }).shape({
      id: stringType(),
      componentName: stringType(),
      customRenderId: stringType(),
      firstBay: Section,
      secondBay: Section,
      thirdBay: Section,
      fourthBay: Section,
      bentoSponsoredBanner: SponsoredBanner,
      theme: stringType(),
    }),
  }),
}, SponsoredBannerComponent);
